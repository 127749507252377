import styled from 'styled-components';
import URLlines from 'src/assets/lines.png';
import BlumLogo from 'src/components/atoms/BlumLogo';

export const FormUnauthenticatedSmall = styled.form`
  position: relative;
  border-radius: 32px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 120px) {
    width: 100%;
    max-width: 440px;
    padding: 2rem 1.5rem;
    min-height: 580px;
    height: 100%;
    width: 100%;
    max-width: 500px;
  }

  @media (min-width: 768px) {
    padding: 2rem 3rem;
  }

  @media (min-width: 1024px) {
    height: auto;
    width: 100%;
    min-height: 580px;
    min-width: 520px;
    padding: 2rem 3rem;
  }

  @media (min-width: 1280px) {
    min-width: 528px;
  }

  @media (min-width: 1536px) {
    min-width: 550px;
  }
`;

const Template = styled.div`
  background-image: url(${URLlines.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #004b76;
  grid-template-columns: repeat(2, 1fr);
`;

const UnauthenticatedTemplate = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <Template className="bl-px-4 bl-w-full bl-min-h-screen bl-h-auto md:bl-grid bl-block bl-grid-cols-1 md:bl-grid-cols-2 bl-items-center">
      <div className="bl-mx-auto bl-text-center bl-text-neutral-light bl-hidden md:bl-block">
        <figure className="bl-w-62 lg:bl-w-76 xl:bl-w-92 bl-h-36 lg:bl-h-44 xl:bl-h-52">
          <BlumLogo className="bl-w-full bl-h-full" />
        </figure>
      </div>
      <div className="bl-mx-4 md:bl-mx-auto bl-flex bl-flex-col bl-items-center bl-justify-center bl-min-h-screen bl-py-4">
        <BlumLogo
          width={130}
          height={60}
          className="bl-text-white bl-mb-5 bl-block md:bl-hidden"
        />
        {children}
      </div>
    </Template>
  );
};

export default UnauthenticatedTemplate;
