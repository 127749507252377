import Link, { LinkProps } from 'next/link';

interface Props extends LinkProps {
  className?: string;
  children?: React.ReactNode;
}

const CustomLink = ({ className = '', children, ...props }: Props) => {
  return (
    <Link
      {...props}
      passHref
      className={className}
      data-testid={props['data-testid'] || ''}
    >
      {children}
    </Link>
  );
};

export default CustomLink;
