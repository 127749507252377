export interface AnalyticsServiceProps {}

export enum ActionResult {
  OK = 'ok',
  ERROR = 'error',
}

export class AnalyticsService {
  private dataLayer: any[];

  constructor() {
    if (typeof window !== 'undefined') {
      this.dataLayer = (window as any).dataLayer;
    }
  }

  public pushRender(action: string) {
    this.push({ action });
  }

  public pushActionOk(action: string) {
    this.push({ action, status: ActionResult.OK });
  }

  public pushActionError(action: string) {
    this.push({ action, status: ActionResult.ERROR });
  }

  public push({ action, status }: { action: string; status?: ActionResult }) {
    if (status) {
      this.dataLayer?.push({
        event: 'virtualEvent',
        category: 'Blum Portal',
        action: status ? `${action}:${status}` : action,
        label: '(not available)',
      });
    }
  }
}
