import { useState } from 'react';
import dynamic from 'next/dynamic';
import { Props as OtpFormProps } from 'src/components/organisms/forms/OtpForm';
import SignInForm, { SignInFormPropsI } from './components/SignInForm';
import Loader from 'src/components/molecules/Loader';
import { useRouter } from 'next/router';
import { privateRoutes } from 'src/routes';

const CompleteNewPassword = dynamic(
  () => import('src/components/organisms/forms/CompleteNewPassword'),
  {
    loading: () => <Loader />,
  }
);

const OtpForm = dynamic(
  () => import('src/components/organisms/forms/OtpForm'),
  {
    loading: () => <Loader />,
  }
);

enum StateForm {
  SIGNIN = 'SIGNIN',
  OTP = 'OTP',
  COMPLETE_NEW_PASSWORD = 'COMPLETE_NEW_PASSWORD',
}

const SignIn = () => {
  const [stateForm, setStateForm] = useState<StateForm>(StateForm.SIGNIN);
  const [unconfirmedEmail, setUnconfirmedEmail] = useState<string>('');
  const [cognitoUser, setCognitoUser] = useState<any>();

  const router = useRouter();

  const onSuccessSignIn = (data: any) => {
    if (data?.challenge?.name === 'NEW_PASSWORD_REQUIRED') {
      setStateForm(StateForm.COMPLETE_NEW_PASSWORD);
      setCognitoUser(data?.challenge?.user);
      return;
    }

    router.push(privateRoutes.home.path);
  };

  const onUnconfirmedError: SignInFormPropsI['onUnconfirmedError'] = async (
    paramUnconfirmedEmail
  ) => {
    setUnconfirmedEmail(paramUnconfirmedEmail);
    setStateForm(StateForm.OTP);
  };

  const onConfirmationOk: OtpFormProps['onSuccess'] = () =>
    setStateForm(StateForm.SIGNIN);

  return (
    <>
      {stateForm === StateForm.SIGNIN && (
        <SignInForm
          defaultEmail={unconfirmedEmail}
          onSuccessSignIn={onSuccessSignIn}
          onUnconfirmedError={onUnconfirmedError}
        />
      )}
      {stateForm === StateForm.OTP && (
        <OtpForm
          email={unconfirmedEmail}
          onSuccess={onConfirmationOk}
          resentOtp
        />
      )}
      {stateForm === StateForm.COMPLETE_NEW_PASSWORD && (
        <CompleteNewPassword cognitoUser={cognitoUser} />
      )}
    </>
  );
};

export default SignIn;
