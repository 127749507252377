import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

const ContainerReCaptcha = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  @media (max-width: 400px) {
    justify-content: start;
    overflow-x: auto;
    max-width: 100%;
  }
`;

export type InputCaptchaRef = {
  getValue: () => string;
  reset: () => void;
};

export type InputCaptchaProps = {
  captchaRef: React.RefObject<InputCaptchaRef>;
  onError: () => void;
};

export const isCaptchaDisabled = () =>
  process.env.NEXT_PUBLIC_RECAPTCHA_DISABLED &&
  process.env.NEXT_PUBLIC_RECAPTCHA_DISABLED.toLowerCase() === 'true';

export const isCaptchaValid = (ref: React.RefObject<InputCaptchaRef>) =>
  isCaptchaDisabled() || ref.current?.getValue();

const InputCaptcha = ({ captchaRef, onError }: InputCaptchaProps) => {
  const captchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_KEY;

  const onCaptchaChange = () => {
    if (!isCaptchaDisabled() && !captchaRef.current?.getValue()) {
      onError();
    }
  };

  return (
    <ContainerReCaptcha>
      <ReCAPTCHA
        sitekey={captchaKey}
        ref={captchaRef}
        onChange={onCaptchaChange}
      />
    </ContainerReCaptcha>
  );
};

export default InputCaptcha;
