import { ReactElement } from 'react';

import Signin from 'src/views/signin';
import { NextPageWithLayout } from './_app';
import UnauthenticatedTemplate from 'src/components/templates/UnauthenticatedTemplate';

const SigninPage: NextPageWithLayout = () => <Signin />;

SigninPage.getLayout = (page: ReactElement) => (
  <UnauthenticatedTemplate>{page}</UnauthenticatedTemplate>
);

export default SigninPage;
