import styled from 'styled-components';

const ContainerMessageError = styled.div`
  height: 1.4rem;
  text-align: center;

  & p {
    color: red;
    font-size: 14px;
    padding: 2px 0;
  }
`;

export type ErrorMessageProps = {
  message?: string;
  testId?: string;
};

export const ErrorMessage = ({ message, testId }: ErrorMessageProps) => (
  <ContainerMessageError>
    {message && <p data-testid={testId || 'error-message'}>{message}</p>}
  </ContainerMessageError>
);
